import { useParams, RouteComponentProps } from '@reach/router';
import { addDays, format } from 'date-fns';
import { BlobServiceClient } from '@azure/storage-blob';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as uuid from 'uuid';
import Button from '../../components/Button';
import FileUpload from '../../components/FileUpload';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Table, { TableData, TableHeader, TableRow } from '../../components/Table';
import { Tab, TabsContainer } from '../../components/Tabs';
import { useUser } from '../../queries/Accounts';
import { UpsertCallOffWithoutOrderIdDTO, CallOffItem, CallOff } from '../../queries/Calloff';
import { Delivery, DeliveryDTO, DeliveryItem } from '../../queries/Delivery';
import {
    Order,
    useApproveOrder,
    useCancelOrder,
    useCreateOrderCallOff,
    useCreateOrderDelivery,
    useGetOrder,
    useSendOrder,
} from '../../queries/Orders';
import { RequisitionItem } from '../../queries/Requisitions';
import {
    formatCurrencyAmount,
    handleWebDownload,
    validateEmail,
    fomatCurrencySymbol,
} from '../../utils/Utils';
import { domain } from '../../d-man';
import TypeAhead from '../../components/TypeAhead';
import { useGetUsers } from '../../queries/Users';
import { useGetPermissions } from '../../queries/Permissions';
import Select from '../../components/Select';
import { ProjectAddress } from '../../queries/Projects';
import TextArea from '../../components/TextArea';
import Loader from '../../components/Loader';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function OrderDetail(props: RouteComponentProps) {
    const { navigate } = props;
    // CONSTANTS - TODO: Move!
    const DECIMAL_PLACES = 3;

    const user = useUser();

    const MAX_USERS = 10000;
    const users = useGetUsers(0, MAX_USERS);

    const params = useParams();
    const getId = () => params.id;

    const order = useGetOrder(getId());
    const orderApprove = useApproveOrder(getId());
    const orderCancel = useCancelOrder(getId());

    const [deliveryErrorMessage, setDeliveryErrorMessage] = useState('');
    const [callOffErrorMessage, setCallOffErrorMessage] = useState('');
    const [sendOrderErrorMessage, setSendOrderErrorMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [approvingOrder, setApprovingOrder] = useState(false);

    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openSendOrderModal, setOpenSendOrderModal] = useState(false);
    const [openDownloadOrderModal, setOpenDownloadOrderModal] = useState(false);
    const [openCreateDeliveryModal, setOpenCreateDeliveryModal] = useState(false);
    const [openCreateCallOffModal, setOpenCreateCallOffModal] = useState(false);
    const [tabs, setTabs] = useState([] as Tab[]);
    const [selectedTab, setSelectedTab] = useState('');

    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [deliveryNote, setDeliveryNote] = useState('');
    const [deliveryFiles, setDeliveryFiles] = useState([] as File[]);
    const [returnFiles, setReturnFiles] = useState([] as File[]);
    const [deliveryItems, setDeliveryItems] = useState([] as DeliveryItem[]);
    const [email, setEmail] = useState('');
    const [returnNote, setReturnNote] = useState('');
    const [payByDate, setPayByDate] = useState(new Date() as Date | undefined);

    const [callOffDeliveryDate, setCallOffDeliveryDate] = useState(addDays(new Date(), 1));
    const [callOffNote, setCallOffNote] = useState('');
    const [callOffItems, setCallOffItems] = useState([] as CallOffItem[]);

    const [deliveryAddressId, setDeliveryAddressId] = useState('');

    const orderDeliveryCreate = useCreateOrderDelivery(getId());
    const callOffCreate = useCreateOrderCallOff(getId());
    const sendOrder = useSendOrder(getId());

    const getPermissions = useGetPermissions('ORDER', getId());

    const formatEmails = () => {
        if (users && users.data && users.data?.length > 0) {
            return users.data.map((theUser: { email: string }) => ({
                id: theUser.email,
                name: theUser.email,
            }));
        }
        return [];
    };

    const usersRef = useRef(users);

    useEffect(() => {
        usersRef.current.reset();
    }, []);

    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Order_Show_Order_Details_screen')
            ?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    useEffect(() => {
        if (order.data && getPermissions.data) {
            const { type, lineItems } = order.data;
            if (tabs.length === 0) {
                if (
                    !getPermissions.data?.items.find((p) => p.code === 'Order_Show_Deliveries_Tab')
                        ?.hasAccess
                ) {
                    setTabs([{ label: 'Materials', id: 'Materials' }]);
                } else if (type === 'SINGLE') {
                    // single order
                    setTabs([
                        { label: 'Materials', id: 'Materials' },
                        { label: 'Deliveries', id: 'Deliveries' },
                    ]);
                } else {
                    setTabs([
                        { label: 'Materials', id: 'Materials' },
                        { label: 'Call-Offs', id: 'Call-Offs' },
                    ]);
                }
                setSelectedTab('Materials');
            }

            if (lineItems) {
                if (deliveryItems.length === 0) {
                    setDeliveryItems(
                        lineItems.map(
                            (i: RequisitionItem) =>
                                ({
                                    lineItemId: i.id,
                                    quantity: 0,
                                    returnedQuantity: 0,
                                } as DeliveryItem)
                        )
                    );
                }

                if (type === 'BULK' && callOffItems.length === 0) {
                    setCallOffItems(
                        lineItems.map(
                            (i: RequisitionItem) =>
                                ({
                                    lineItemId: i.id,
                                    quantity: 0,
                                } as CallOffItem)
                        )
                    );
                }
            }
        }
    }, [tabs, order.data, deliveryItems, callOffItems, user, getPermissions.data]);

    const getStatus = (orderStatus: Order) => {
        if (orderStatus.cancelledAt !== null || orderStatus.cancelledBy !== null) {
            return 'Cancelled';
        }
        if (orderStatus.exportedAt !== null || orderStatus.exportedBy !== null) {
            return 'Exported';
        }
        if (orderStatus.approvedAt !== null || orderStatus.approvedBy !== null) {
            return 'Approved';
        }
        return 'Draft';
    };

    const handleCancel = () => {
        setIsLoading(true);
        orderCancel.execute().then((result) => {
            if (!result[0]) {
                order.reset();
                if (props.navigate) {
                    props.navigate(`/orders`);
                }
            } else {
                setErrorMessage(result[0]);
            }
            setIsLoading(false);
        });
    };

    const getOrderTotal = (lineItems: RequisitionItem[]) => {
        return (
            lineItems &&
            `${formatCurrencyAmount(
                lineItems.reduce(
                    (a, b) => ({
                        displayAmount: a.displayAmount + (b.quantity || 0) * (b.buyOutRate || 0),
                    }),
                    {
                        displayAmount: 0,
                    }
                ).displayAmount || 0
            )}`
        );
    };

    const getOrderRawTotal = (lineItems: RequisitionItem[]) => {
        return (
            (lineItems &&
                lineItems.reduce(
                    (a, b) => ({
                        displayAmount: a.displayAmount + (b.quantity || 0) * (b.buyOutRate || 0),
                    }),
                    {
                        displayAmount: 0,
                    }
                ).displayAmount) ||
            0
        );
    };

    const getVatTotal = (lineItems: RequisitionItem[]) => {
        const vat = lineItems[0].vat || 0;
        const vatTotal = getOrderRawTotal(lineItems || []) * (vat / 100);
        return parseFloat(vatTotal.toFixed(2));
    };

    const getTotalAmount = (subTotal: number, vatTotal: number) => {
        return formatCurrencyAmount(parseFloat((subTotal + vatTotal).toFixed(2)));
    };

    const getLineItemNumber = (row: RequisitionItem) => {
        if (!row?.id) return '';
        const lineItemIndex = order?.data?.lineItems?.findIndex((item) => item.id === row.id);
        return !lineItemIndex || lineItemIndex === -1 ? 1 : lineItemIndex + 1;
    };

    const handleCreateDeliveryModalOpen = () => {
        setOpenCreateDeliveryModal(true);
    };

    const handleCreateDeliveryModalClose = () => {
        setOpenCreateDeliveryModal(false);
    };

    const handleCreateCallOffModalOpen = () => {
        setOpenCreateCallOffModal(true);
    };

    const handleCreateCallOffModalClose = () => {
        setOpenCreateCallOffModal(false);
    };

    const handleSendOrderModalOpen = () => {
        setOpenSendOrderModal(true);
    };

    const handleSendOrderModalClose = () => {
        setOpenSendOrderModal(false);
    };

    const round = (value: number, decimals: number) => {
        return Number(`${Math.round(Number(`${value}e${decimals}`))}e-${decimals}`);
    };

    const uploadFiles = async (files: File[]) => {
        const blobs: { id: string; name: string }[] = [];
        if (files) {
            const sasUrl = process.env.REACT_APP_AZURE_BLOB_CONNECTIONSTRING || '';
            const blobServiceClient = new BlobServiceClient(sasUrl);
            const containerClient = blobServiceClient.getContainerClient('uploads');
            await containerClient.createIfNotExists();

            // eslint-disable-next-line no-restricted-syntax
            for (const file of files) {
                const id = uuid.v4();
                const blockBlobClient = containerClient.getBlockBlobClient(id);
                // eslint-disable-next-line no-await-in-loop
                const uploadBlobResponse = await blockBlobClient.uploadData(file);
                if (uploadBlobResponse.requestId) {
                    blobs.push({
                        id,
                        name: file.name,
                    });
                }
            }
        }
        return blobs;
    };

    const postCreateDelivery = async () => {
        if (
            !deliveryDate ||
            !deliveryNote ||
            !payByDate ||
            (deliveryItems.every((i) => i.returnedQuantity <= 0) &&
                deliveryItems.every((i) => i.quantity <= 0))
        ) {
            setDeliveryErrorMessage('Please capture all the fields below');
        } else if (
            deliveryItems.some((i) => i.returnedQuantity) &&
            (returnFiles.length <= 0 || !returnNote)
        ) {
            setDeliveryErrorMessage('Please capture all the fields below');
        } else if (deliveryFiles.length <= 0) {
            setDeliveryErrorMessage('You must upload at least 1 file');
        } else if (order.data) {
            setIsLoading(true);
            // upload
            const blobs = await uploadFiles(deliveryFiles);
            const returnBlobs = await uploadFiles(returnFiles);

            if (blobs.length !== deliveryFiles.length) {
                setDeliveryErrorMessage('Not all files were uploaded successfully');
                setIsLoading(false);
                return;
            }

            if (returnBlobs.length !== returnFiles.length) {
                setDeliveryErrorMessage('Not all return files were uploaded successfully');
                setIsLoading(false);
                return;
            }

            const data: DeliveryDTO = {
                deliveredAt: deliveryDate,
                note: deliveryNote,
                items: deliveryItems.filter((i) => i.quantity > 0 || i.returnedQuantity > 0),
                blobs,
                returnNote,
                returnBlobs,
                payByDate,
            };

            await orderDeliveryCreate.execute(data);
            setIsLoading(false);
            order.reset();
        }
    };

    useEffect(() => {
        if (orderDeliveryCreate.error) {
            setDeliveryErrorMessage(orderDeliveryCreate.error);
        }
    }, [orderDeliveryCreate.error]);

    useEffect(() => {
        handleCreateDeliveryModalClose();
    }, [orderDeliveryCreate.data]);

    const postCreateCallOff = async () => {
        if (!callOffDeliveryDate || callOffItems.every((i) => i.quantity <= 0)) {
            setCallOffErrorMessage('Please capture all the fields above');
        } else {
            setIsLoading(true);
            const data: UpsertCallOffWithoutOrderIdDTO = {
                requiredAt: format(callOffDeliveryDate, 'yyyy-MM-dd'),
                description: callOffNote,
                items: callOffItems.filter((i) => i.quantity > 0),
            };
            await callOffCreate.execute(data);
            setIsLoading(false);
            order.reset();
        }
    };

    useEffect(() => {
        if (callOffCreate.error) {
            setCallOffErrorMessage(callOffCreate.error);
        }
    }, [callOffCreate.error]);

    useEffect(() => {
        handleCreateCallOffModalClose();
    }, [callOffCreate.data]);

    useEffect(() => {
        if (
            order.data?.requisition?.project?.addresses &&
            order.data?.requisition?.project?.addresses?.length
        ) {
            setDeliveryAddressId(order.data?.requisition?.project?.addresses[0].id || '');
        }
    }, [order.data]);

    const postSendOrder = async () => {
        if (!email) {
            setSendOrderErrorMessage('Please capture all the fields above');
        } else if (!validateEmail(email)) {
            setSendOrderErrorMessage('Please provide a valid email address');
        } else {
            setIsLoading(true);
            await sendOrder.execute({
                email,
                deliveryAddressId,
            });
            // I cant get this error to work!
            if (sendOrder.error) {
                // Display create order error here
            } else {
                handleSendOrderModalClose();
            }
            order.reset();
            setIsLoading(false);
        }
    };

    const handleDownload = async (endpoint: string) => {
        setIsLoading(true);
        const [, data, response] = await domain
            .get(endpoint, {
                requestConfig: {
                    responseType: 'blob', // important
                },
            })
            .execute();
        setIsLoading(false);
        if (!data) return;

        handleWebDownload(data, response);
        order.reset();
    };

    const postDownloadOrder = async () => {
        await handleDownload(
            `/orders/${order.data?.id}/download?deliveryAddressId=${deliveryAddressId}`
        );
        setOpenDownloadOrderModal(false);
    };

    const updateUploadedFiles = (files: File[]) => {
        setDeliveryFiles(files);
    };

    const updateUploadedFile = (files: File[]) => {
        setReturnFiles(files);
    };

    const toCallOffDetail = (id?: string) => {
        if (props.navigate) {
            props.navigate(`/calloffs/${id}`);
        }
    };

    const toDeliveryDetail = (id?: string) => {
        if (props.navigate) {
            props.navigate(`/deliveries/${id}`);
        }
    };

    const toRequisitionDetail = (id?: string) => {
        if (props.navigate) {
            props.navigate(`/requisitions/${id}`);
        }
    };

    const formatDeliveryAddresses = () => {
        if (order.data && order.data.requisition?.project?.addresses) {
            const filteredAddresses = order.data.requisition?.project?.addresses.map(
                (address: ProjectAddress, i) =>
                    ({
                        value: address.id,
                        label: `Address ${i + 1}`,
                    } as { value: string; label: string })
            );
            return filteredAddresses;
        }
        return [];
    };

    const formatProjectAddressSelections = () => {
        if (order?.data?.requisition && order.data?.requisition?.project?.addresses) {
            const filteredAddresses = order?.data?.requisition.project?.addresses.map(
                (address: ProjectAddress) =>
                    ({
                        value: address.id,
                        label: `${address.address1} ${address.address2} ${address.address3}`,
                    } as { value: string; label: string })
            );
            return filteredAddresses;
        }
        return [];
    };

    const formatCreditorAddress = () => {
        if (order.data && order.data.lineItems && order.data.lineItems[0]?.creditor) {
            const { creditor } = order.data.lineItems[0];
            return `${creditor.address1}, ${creditor.address2}, ${creditor.address3}`;
        }
        return '';
    };

    const getDeliveryAddressDetails = () => {
        if (order.data && order.data.requisition?.project?.addresses && deliveryAddressId) {
            const filteredAddress = order.data.requisition?.project?.addresses.find(
                (i) => i.id === deliveryAddressId
            );
            const addressLines = [];
            if (filteredAddress?.address1) {
                addressLines.push(filteredAddress?.address1);
            }
            if (filteredAddress?.address2) {
                addressLines.push(filteredAddress?.address2);
            }
            if (filteredAddress?.address3) {
                addressLines.push(filteredAddress?.address3);
            }
            if (filteredAddress?.postCode) {
                addressLines.push(filteredAddress?.postCode);
            }
            return addressLines.join('\n') || '';
        }
        return '';
    };

    const getUserFullName = (userId: string) => {
        const chosenUser =
            userId &&
            users.data?.find((userItem) => userItem.id?.toUpperCase() === userId.toUpperCase());
        if (chosenUser) {
            return `${chosenUser.firstName} ${chosenUser.lastName}`;
        }
        return '';
    };

    const renderOrder = () => {
        if (!order || order.loading) {
            return (
                <tr>
                    <td className="border p-2" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <div className="pt-3 pb-3">
                                <svg
                                    className="w-8 h-8 animate-spin text-orange-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                            </div>
                            <div className="text-sm">Fetching Order from server</div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (order.error) {
            return (
                <tr>
                    <td className="border p-2" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <div className="pt-3 pb-3">
                                <svg
                                    className="text-red-500 w-8 h-8 animate-pulse"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </div>
                            <div className="text-sm">
                                Failed to fetch Order from the server ({order.error})
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (order.data) {
            const {
                number,
                createdAt,
                type,
                requisition,
                lineItems,
                approvedAt,
                approvedBy,
                requiredAt,
                cancelledAt,
                cancelledBy,
            } = order.data;

            return (
                <div className="text-lg bg-gray-400">
                    <div className="text-xl p-3 font-bold">Order Information</div>
                    <hr />
                    <div className="w-full flex">
                        <div className="flex-col p-4 w-1/4 text-xs border-r">
                            <div className="mb-1 flex justify-between">
                                <div className="font-bold">Created At</div>
                                <Input
                                    value={
                                        createdAt
                                            ? format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss')
                                            : ''
                                    }
                                    type="text"
                                    disabled
                                />
                            </div>
                            <div className="mb-1 flex justify-between">
                                <div className="font-bold">Status</div>
                                <Input value={getStatus(order.data)} type="text" disabled />
                            </div>
                            <div className="mb-1 flex justify-between">
                                <div className="font-bold">Order Type</div>
                                <Input value={type?.toString() || ''} type="text" disabled />
                            </div>
                            <div className="mb-1 flex justify-between">
                                <div className="font-bold">Job Code</div>
                                <Input value={requisition?.project?.jobCode} type="text" disabled />
                            </div>
                            <div className="mb-1 flex justify-between">
                                <div className="font-bold">Supplier Code</div>
                                <Input
                                    value={lineItems && lineItems[0].creditor?.code}
                                    type="text"
                                    disabled
                                />
                            </div>
                            <div className="mb-1 flex justify-between">
                                <div className="font-bold">Order No.</div>
                                <Input
                                    value={`${number?.toString().padStart(6, '0')}`}
                                    type="text"
                                    disabled
                                />
                            </div>
                            <div className="mb-1 flex justify-between">
                                <div className="font-bold">Req No.</div>
                                <Input
                                    value={`${requisition?.number?.toString().padStart(6, '0')}`}
                                    type="text"
                                    disabled
                                />
                            </div>
                            <div className="mb-1 flex justify-between">
                                <div className="font-bold">Created By</div>
                                <Input
                                    value={`${getUserFullName(requisition?.createdBy || '')}`}
                                    type="text"
                                    disabled
                                />
                            </div>
                            <div className="mb-1 flex justify-between">
                                <div className="font-bold">Approved By</div>
                                <Input
                                    value={`${getUserFullName(approvedBy || '')}`}
                                    type="text"
                                    disabled
                                />
                            </div>
                            <div className="mb-1 flex justify-between">
                                <div className="font-bold">Approved At</div>
                                <Input
                                    value={
                                        approvedAt
                                            ? format(
                                                  new Date(approvedAt || ''),
                                                  'yyyy-MM-dd HH:mm:ss'
                                              )
                                            : ''
                                    }
                                    type="text"
                                    disabled
                                />
                            </div>
                            <div className="mb-1 flex justify-between">
                                <div className="font-bold">Required At</div>
                                <Input
                                    value={
                                        requiredAt
                                            ? format(new Date(requiredAt || ''), 'yyyy-MM-dd')
                                            : ''
                                    }
                                    type="text"
                                    disabled
                                />
                            </div>
                            {cancelledAt && cancelledBy && (
                                <div>
                                    <div className="mb-1 flex justify-between">
                                        <div className="font-bold">Cancelled By</div>
                                        <Input
                                            value={`${getUserFullName(cancelledBy || '')}`}
                                            type="text"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-1 flex justify-between">
                                        <div className="font-bold">Cancelled At</div>
                                        <Input
                                            value={
                                                cancelledAt
                                                    ? format(
                                                          new Date(cancelledAt || ''),
                                                          'yyyy-MM-dd HH:mm:ss'
                                                      )
                                                    : ''
                                            }
                                            type="text"
                                            disabled
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex justify-between gap-6 p-4 text-xs w-3/4">
                            <div className="flex flex-col gap-2 w-1/2">
                                <span className="font-bold text-lg">Creditor Details</span>
                                <hr />
                                <div className="w-full justify-between flex items-center">
                                    <p className="w-1/4 font-bold">Contact Person</p>
                                    <div className="w-3/4">
                                        <Input
                                            value={
                                                (lineItems &&
                                                    lineItems[0].creditor?.contactPerson) ||
                                                ''
                                            }
                                            type="text"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full justify-between flex items-center">
                                    <p className="w-1/4 font-bold">Tel.</p>
                                    <div className="w-3/4">
                                        <Input
                                            value={`${
                                                (lineItems && lineItems[0].creditor?.tel) || ''
                                            }`}
                                            type="text"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full justify-between flex">
                                    <p className="w-1/4 font-bold">Cell.</p>
                                    <div className="w-3/4">
                                        <Input
                                            value={(lineItems && lineItems[0].creditor?.cell) || ''}
                                            type="text"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full justify-between flex">
                                    <p className="w-1/4 font-bold">Email</p>
                                    <div className="w-3/4">
                                        <Input
                                            value={
                                                (lineItems && lineItems[0].creditor?.email) || ''
                                            }
                                            type="text"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full justify-between flex">
                                    <p className="w-1/4 font-bold">Address</p>
                                    <div className="w-3/4">
                                        <Input
                                            value={(lineItems && formatCreditorAddress()) || ''}
                                            type="text"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 w-1/2">
                                <span className="font-bold text-lg">Project Details</span>
                                <hr />
                                <div className="w-full justify-between flex">
                                    <p className="w-1/4 font-bold">Contact Person</p>
                                    <div className="w-3/4">
                                        <Input
                                            value={requisition?.project?.contactPerson || ''}
                                            type="text"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full justify-between flex">
                                    <p className="w-1/4 font-bold">Tel.</p>
                                    <div className="w-3/4">
                                        <Input
                                            value={requisition?.project?.tel || ''}
                                            type="text"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full justify-between flex">
                                    <p className="w-1/4 font-bold">Cell.</p>
                                    <div className="w-3/4">
                                        <Input
                                            value={requisition?.project?.cell || ''}
                                            type="text"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full justify-between flex">
                                    <p className="w-1/4 font-bold">Email</p>
                                    <div className="w-3/4">
                                        <Input
                                            value={requisition?.project?.email || ''}
                                            type="text"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div>
                                    {requisition?.project?.addresses && (
                                        <div className="w-full justify-between flex">
                                            <p className="font-bold">
                                                {requisition?.project?.addresses.length > 1
                                                    ? 'Delivery Addresses'
                                                    : 'Delivery Address'}
                                            </p>
                                            <select className="w-3/4 bg-gray-100">
                                                {formatProjectAddressSelections().map(
                                                    (option: { value: string; label: string }) => (
                                                        <option>{option.label}</option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <hr />
                </div>
            );
        }
        return null;
    };

    const renderOrderButtons = () => {
        if (order.data) {
            const { requisition } = order.data;
            return (
                <div className="flex space-x-4 p-4">
                    <div className="mb-2">
                        <Button onClick={() => toRequisitionDetail(requisition?.id)}>
                            Go To Requisition
                        </Button>
                    </div>

                    {getPermissions.data?.items.find((p) => p.code === 'Order_Approve')
                        ?.hasAccess &&
                        order.data.approvedAt === null &&
                        order.data.approvedBy === null &&
                        order.data.cancelledAt === null &&
                        order.data.cancelledBy === null && (
                            <div>
                                <Button
                                    onClick={() => {
                                        setApprovingOrder(true);
                                        orderApprove.execute().then(() => {
                                            setApprovingOrder(false);
                                            order.reset();
                                        });
                                    }}
                                    disabled={approvingOrder}
                                >
                                    {approvingOrder ? 'Loading' : 'Approve'}
                                </Button>
                            </div>
                        )}
                    {order.data &&
                        getPermissions.data?.items.find((p) => p.code === 'Order_Download')
                            ?.hasAccess && (
                            <div>
                                <Button onClick={() => setOpenDownloadOrderModal(true)}>
                                    Download Order
                                </Button>
                            </div>
                        )}
                    {order.data &&
                        getPermissions.data?.items.find((p) => p.code === 'Order_Send')
                            ?.hasAccess && (
                            <div>
                                <Button onClick={handleSendOrderModalOpen}>Send Order</Button>
                            </div>
                        )}
                    {((order.data.exportedAt == null &&
                        order.data.exportedBy == null &&
                        order.data.cancelledAt === null &&
                        order.data.cancelledBy === null &&
                        getPermissions.data?.items.find((p) => p.code === 'Order_Export')
                            ?.hasAccess) ||
                        getPermissions.data?.items.find((p) => p.code === 'Order_Export_Override')
                            ?.hasAccess) &&
                        order.data.approvedAt !== null &&
                        order.data.approvedBy !== null && (
                            <div>
                                <Button
                                    onClick={() =>
                                        handleDownload(`/orders/${order.data?.id}/export`)
                                    }
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Loading' : 'Export'}
                                </Button>
                            </div>
                        )}
                    {getPermissions.data?.items.find((p) => p.code === 'Order_Cancel')?.hasAccess &&
                        order.data &&
                        order.data.cancelledAt === null &&
                        order.data.cancelledBy === null && (
                            <div>
                                <Button onClick={() => setOpenCancelModal(true)}>
                                    Cancel Order
                                </Button>
                            </div>
                        )}
                </div>
            );
        }
        return <div />;
    };

    const getTotalDelivered = (reqItemParam: RequisitionItem, orderParam?: Order) => {
        if (
            orderParam &&
            orderParam.type === 'SINGLE' &&
            orderParam.orderDeliveries &&
            orderParam.orderDeliveries.length > 0
        ) {
            const { quantity } = orderParam.orderDeliveries
                .map((i) =>
                    i.items
                        .filter((j) => j.lineItemId === reqItemParam.id)
                        .reduce((a, b) => ({ lineItemId: '', quantity: a.quantity + b.quantity }), {
                            lineItemId: '',
                            quantity: 0,
                        })
                )
                .reduce((a, b) => ({ lineItemId: '', quantity: a.quantity + b.quantity }), {
                    lineItemId: '',
                    quantity: 0,
                });
            return round(quantity, DECIMAL_PLACES);
        }
        return 0;
    };

    const getTotalCalledOff = (reqItemParam: RequisitionItem, orderParam?: Order) => {
        if (orderParam && orderParam.callOffs && orderParam.callOffs.length > 0) {
            const { quantity } = orderParam.callOffs
                .map((i) =>
                    i.items
                        .filter((j) => j.lineItemId === reqItemParam.id)
                        .reduce((a, b) => ({ lineItemId: '', quantity: a.quantity + b.quantity }), {
                            lineItemId: '',
                            quantity: 0,
                        })
                )
                .reduce((a, b) => ({ lineItemId: '', quantity: a.quantity + b.quantity }), {
                    lineItemId: '',
                    quantity: 0,
                });
            return round(quantity, DECIMAL_PLACES);
        }
        return 0;
    };

    const checkDeliveriesAndCalloffs = () => {
        if (order.data) {
            if (
                (order.data?.callOffs && order.data.callOffs?.length > 0) ||
                (order.data?.orderDeliveries && order.data.orderDeliveries?.length > 0)
            ) {
                return (
                    <span className="text-sm text-red-700">
                        [Warning: Order has existing deliveries/calloffs.]
                    </span>
                );
            }
        }
        return '';
    };

    return (
        <>
            {!getPermissions.data && <Loader message="Loading order..." />}
            {getPermissions.data && order.data && (
                <div className="flex flex-col">
                    <Modal
                        title="Cancel Order"
                        onClose={() => setOpenCancelModal(false)}
                        isOpen={openCancelModal}
                    >
                        <div className="w-72">
                            <div className="flex flex-col justify-between mt-5">
                                Are you sure you want to cancel this order?
                                {checkDeliveriesAndCalloffs()}
                            </div>
                            <div />
                            <div className="flex flex-row-reverse">
                                <div className="ml-3">
                                    <Button onClick={handleCancel} disabled={isLoading}>
                                        {isLoading ? 'Loading' : 'Yes'}
                                    </Button>
                                </div>
                                <div>
                                    <Button secondary onClick={() => setOpenCancelModal(false)}>
                                        No
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        title="New Delivery"
                        // subtitle={`${order.data.number.toString().padStart(6, '0')}`}
                        onClose={handleCreateDeliveryModalClose}
                        isOpen={openCreateDeliveryModal}
                        onAction={postCreateDelivery}
                        actionText={isLoading ? 'Loading' : ''}
                    >
                        <div>
                            {deliveryErrorMessage && (
                                <div className="flex mb-5 text-red-500 text-xl font-semibold">
                                    {deliveryErrorMessage}
                                </div>
                            )}
                            <div className="flex justify-between">
                                <div>
                                    <div className="w-full h-full mb-5">
                                        <div className="text-sm pb-1">Material Information</div>
                                        <table className="table-auto bg-white w-full">
                                            <thead>
                                                <TableRow>
                                                    <TableHeader>No</TableHeader>
                                                    <TableHeader>Description</TableHeader>
                                                    <TableHeader>Cost Code</TableHeader>
                                                    <TableHeader>Creditor</TableHeader>
                                                    <TableHeader>Quantity Remaining</TableHeader>
                                                    <TableHeader>Delivered</TableHeader>
                                                    <TableHeader>Returned</TableHeader>
                                                </TableRow>
                                            </thead>
                                            <tbody>
                                                {order.data &&
                                                    order.data.lineItems &&
                                                    deliveryItems.length > 0 &&
                                                    order.data.lineItems.map(
                                                        (reqItem: RequisitionItem) => {
                                                            return (
                                                                <TableRow hover>
                                                                    <TableData>
                                                                        <div className="p-1">
                                                                            {getLineItemNumber(
                                                                                reqItem
                                                                            )}
                                                                        </div>
                                                                    </TableData>
                                                                    <TableData id={reqItem.id}>
                                                                        {reqItem.description}
                                                                    </TableData>
                                                                    <TableData>
                                                                        {reqItem.costCode &&
                                                                            reqItem.costCode.name}
                                                                    </TableData>
                                                                    <TableData>
                                                                        {reqItem.creditor?.name ||
                                                                            ''}
                                                                    </TableData>
                                                                    <TableData>
                                                                        {order.data?.type ===
                                                                            'SINGLE' &&
                                                                            reqItem.quantity &&
                                                                            round(
                                                                                reqItem.quantity -
                                                                                    getTotalDelivered(
                                                                                        reqItem,
                                                                                        order.data
                                                                                    ),
                                                                                DECIMAL_PLACES
                                                                            )}
                                                                    </TableData>
                                                                    <TableData>
                                                                        <Input
                                                                            value={deliveryItems
                                                                                .filter(
                                                                                    (i) =>
                                                                                        i.lineItemId ===
                                                                                        reqItem.id
                                                                                )[0]
                                                                                .quantity.toString()}
                                                                            onChange={(event) => {
                                                                                const oldDeliveryItem = deliveryItems.find(
                                                                                    (i) =>
                                                                                        i.lineItemId ===
                                                                                        reqItem.id
                                                                                );
                                                                                const newDeliveryItems = deliveryItems.filter(
                                                                                    (i) =>
                                                                                        i.lineItemId !==
                                                                                        reqItem.id
                                                                                );
                                                                                newDeliveryItems.push(
                                                                                    {
                                                                                        lineItemId:
                                                                                            reqItem.id,
                                                                                        quantity: parseFloat(
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        ),
                                                                                        returnedQuantity:
                                                                                            oldDeliveryItem?.returnedQuantity,
                                                                                    } as DeliveryItem
                                                                                );
                                                                                setDeliveryItems(
                                                                                    newDeliveryItems
                                                                                );
                                                                            }}
                                                                            type="number"
                                                                        />
                                                                    </TableData>
                                                                    <TableData>
                                                                        <Input
                                                                            value={deliveryItems
                                                                                .filter(
                                                                                    (i) =>
                                                                                        i.lineItemId ===
                                                                                        reqItem.id
                                                                                )[0]
                                                                                .returnedQuantity?.toString()}
                                                                            onChange={(event) => {
                                                                                const oldDeliveryItem = deliveryItems.find(
                                                                                    (i) =>
                                                                                        i.lineItemId ===
                                                                                        reqItem.id
                                                                                );
                                                                                const newDeliveryItems = deliveryItems.filter(
                                                                                    (i) =>
                                                                                        i.lineItemId !==
                                                                                        reqItem.id
                                                                                );
                                                                                newDeliveryItems.push(
                                                                                    {
                                                                                        lineItemId:
                                                                                            reqItem.id,
                                                                                        returnedQuantity: parseFloat(
                                                                                            event
                                                                                                .target
                                                                                                .value ||
                                                                                                '0'
                                                                                        ),
                                                                                        quantity:
                                                                                            oldDeliveryItem?.quantity,
                                                                                    } as DeliveryItem
                                                                                );
                                                                                setDeliveryItems(
                                                                                    newDeliveryItems
                                                                                );
                                                                            }}
                                                                            type="number"
                                                                        />
                                                                    </TableData>
                                                                </TableRow>
                                                            );
                                                        }
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="ml-5">
                                    <div className="mb-5">
                                        <div>Delivery Date</div>
                                        <div>
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                selected={deliveryDate}
                                                onChange={(date: Date) => setDeliveryDate(date)}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb5">
                                        <div>Pay By</div>
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            selected={payByDate}
                                            onChange={(date: Date) => setPayByDate(date)}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <Input
                                            label="Delivery Note"
                                            placeholder="Add Note Here"
                                            value={deliveryNote}
                                            type="text"
                                            onChange={(event) =>
                                                setDeliveryNote(event.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <div className="text-sm pb-1">Upload Documents</div>
                                        <FileUpload
                                            updateFilesCb={updateUploadedFiles}
                                            onError={(error) => setDeliveryErrorMessage(error)}
                                            // accept=".jpg,.png,.jpeg"
                                            multiple
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <div className="text-sm pb-1">Return (Optional)</div>
                                        <hr />
                                        <br />
                                        <Input
                                            label="Return Note"
                                            placeholder="Add Note Here"
                                            value={returnNote}
                                            type="text"
                                            onChange={(event) => setReturnNote(event.target.value)}
                                        />
                                        <br />
                                        <FileUpload
                                            multiple={false}
                                            updateFilesCb={updateUploadedFile}
                                            onError={(error) => setDeliveryErrorMessage(error)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        title="New Call-Off"
                        // subtitle={`${order.data.number.toString().padStart(6, '0')}`}
                        onClose={handleCreateCallOffModalClose}
                        isOpen={openCreateCallOffModal}
                        onAction={postCreateCallOff}
                        actionText={isLoading ? 'Loading' : ''}
                    >
                        <div className="flex justify-between">
                            <div>
                                <div className="w-full h-full mb-5">
                                    <div className="text-sm pb-1">Material Information</div>
                                    <table className="table-auto bg-white w-full">
                                        <thead>
                                            <TableRow>
                                                <TableHeader>Description</TableHeader>
                                                <TableHeader>Cost Code</TableHeader>
                                                <TableHeader>Creditor</TableHeader>
                                                <TableHeader>Quantity Remaining</TableHeader>
                                                <TableHeader>Call-Off</TableHeader>
                                            </TableRow>
                                        </thead>
                                        <tbody>
                                            {order.data &&
                                                order.data.lineItems &&
                                                callOffItems.length > 0 &&
                                                order.data.lineItems.map(
                                                    (reqItem: RequisitionItem) => {
                                                        return (
                                                            <TableRow hover>
                                                                <TableData id={reqItem.id}>
                                                                    {reqItem.description}
                                                                </TableData>
                                                                <TableData>
                                                                    {reqItem.costCode &&
                                                                        reqItem.costCode.name}
                                                                </TableData>
                                                                <TableData>
                                                                    {reqItem.creditor?.name || ''}
                                                                </TableData>
                                                                <TableData>
                                                                    {reqItem.quantity &&
                                                                        round(
                                                                            reqItem.quantity -
                                                                                getTotalCalledOff(
                                                                                    reqItem,
                                                                                    order.data
                                                                                ),
                                                                            DECIMAL_PLACES
                                                                        )}
                                                                </TableData>
                                                                <TableData>
                                                                    <Input
                                                                        value={callOffItems
                                                                            .filter(
                                                                                (i) =>
                                                                                    i.lineItemId ===
                                                                                    reqItem.id
                                                                            )[0]
                                                                            .quantity.toString()}
                                                                        onChange={(event) => {
                                                                            const newCallOffItems = callOffItems.filter(
                                                                                (i) =>
                                                                                    i.lineItemId !==
                                                                                    reqItem.id
                                                                            );
                                                                            newCallOffItems.push({
                                                                                lineItemId:
                                                                                    reqItem.id,
                                                                                quantity: parseFloat(
                                                                                    event.target
                                                                                        .value
                                                                                ),
                                                                            } as CallOffItem);
                                                                            setCallOffItems(
                                                                                newCallOffItems
                                                                            );
                                                                        }}
                                                                        type="number"
                                                                    />
                                                                </TableData>
                                                            </TableRow>
                                                        );
                                                    }
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="ml-5">
                                <div className="mb-5">
                                    <div>Delivery Date</div>
                                    <div>
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            selected={callOffDeliveryDate}
                                            onChange={(date: Date) => setCallOffDeliveryDate(date)}
                                        />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <Input
                                        label="Notes"
                                        placeholder="Add Notes Here"
                                        value={callOffNote}
                                        type="text"
                                        onChange={(event) => setCallOffNote(event.target.value)}
                                    />
                                </div>
                                <div className="flex mb-5 text-red-500">{callOffErrorMessage}</div>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        title="Download Order"
                        onClose={() => setOpenDownloadOrderModal(false)}
                        isOpen={openDownloadOrderModal}
                    >
                        <div className="flex justify-between">
                            <div>
                                <div className="mb-5">
                                    <Select
                                        label="Delivery Address"
                                        placeholder="Address 1"
                                        options={formatDeliveryAddresses()}
                                        onChange={(event) =>
                                            setDeliveryAddressId(event.target.value)
                                        }
                                    />
                                </div>
                                <div className="mb-5">
                                    <TextArea
                                        label=""
                                        value={getDeliveryAddressDetails()}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div />
                        <div className="flex mb-5 text-red-500">{sendOrderErrorMessage}</div>
                        <div className="flex flex-row-reverse">
                            <div className="pl-2" />
                            <div>
                                <Button onClick={postDownloadOrder} disabled={isLoading}>
                                    {!isLoading ? 'Submit' : <div>Loading</div>}
                                </Button>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        title="Send Order"
                        subtitle="Email Order to email address"
                        onClose={handleSendOrderModalClose}
                        isOpen={openSendOrderModal}
                    >
                        <div className="flex justify-between">
                            <div>
                                <div className="mb-5">
                                    <TypeAhead
                                        label="Email Address"
                                        value={email}
                                        options={formatEmails()}
                                        hideNoMatchMsg
                                        placeholder="Provide Email Address"
                                        onClick={(event) =>
                                            setEmail(
                                                ((event.target as unknown) as {
                                                    id: string;
                                                }).id
                                            )
                                        }
                                    />
                                </div>
                                <div className="mb-5">
                                    <Select
                                        label="Delivery Address"
                                        placeholder="Address 1"
                                        options={formatDeliveryAddresses()}
                                        onChange={(event) =>
                                            setDeliveryAddressId(event.target.value)
                                        }
                                    />
                                </div>
                                <div className="mb-5">
                                    <TextArea
                                        label=""
                                        value={getDeliveryAddressDetails()}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div />
                        <div className="flex mb-5 text-red-500">{sendOrderErrorMessage}</div>
                        <div className="flex flex-row-reverse">
                            <div className="pl-2" />
                            <div>
                                <Button onClick={postSendOrder} disabled={isLoading}>
                                    {!isLoading ? 'Submit' : <div>Loading</div>}
                                </Button>
                            </div>
                        </div>
                    </Modal>
                    <div className="bg-white border rounded-md h-full mx-4 my-4">
                        {renderOrder()}
                    </div>
                    <div className="bg-gray-400 border rounded-md h-full mx-4 my-4">
                        <div className="p-5">
                            <TabsContainer
                                selectedTab={selectedTab}
                                options={tabs}
                                onClick={setSelectedTab}
                            >
                                {selectedTab === 'Materials' && (
                                    <div className="w-full h-full mt-4">
                                        <table className="table-auto bg-white w-full">
                                            <thead>
                                                <TableRow>
                                                    <TableHeader>No</TableHeader>
                                                    <TableHeader width="w-1/3">
                                                        Description
                                                    </TableHeader>
                                                    <TableHeader>Trade Code</TableHeader>
                                                    <TableHeader>Cost Code</TableHeader>
                                                    <TableHeader>Creditor</TableHeader>
                                                    <TableHeader>Unit</TableHeader>
                                                    <TableHeader width="w-6">
                                                        Quantity Remaining
                                                    </TableHeader>
                                                    <TableHeader width="w-6">
                                                        Quantity (Initial)
                                                    </TableHeader>
                                                    <TableHeader>Buy Out Rate</TableHeader>
                                                    <TableHeader>Amount</TableHeader>
                                                </TableRow>
                                            </thead>
                                            <tbody>
                                                {order.data &&
                                                    order.data.lineItems &&
                                                    order.data.lineItems.map(
                                                        (reqItem: RequisitionItem) => (
                                                            <TableRow hover>
                                                                <TableData>
                                                                    {getLineItemNumber(reqItem)}
                                                                </TableData>
                                                                <TableData id={reqItem.id}>
                                                                    {reqItem.description}
                                                                </TableData>
                                                                <TableData>
                                                                    {`${reqItem.costCode?.trade?.description}`}
                                                                </TableData>
                                                                <TableData>{`${reqItem.costCode?.code} - ${reqItem.costCode?.name}`}</TableData>
                                                                <TableData>
                                                                    {reqItem.creditor?.name || ''}
                                                                </TableData>
                                                                <TableData>
                                                                    {reqItem.unit?.description ||
                                                                        ''}
                                                                </TableData>
                                                                <TableData>
                                                                    {order.data?.type === 'BULK' &&
                                                                        reqItem.quantity &&
                                                                        round(
                                                                            reqItem.quantity -
                                                                                getTotalCalledOff(
                                                                                    reqItem,
                                                                                    order.data
                                                                                ),
                                                                            DECIMAL_PLACES
                                                                        )}
                                                                    {order.data?.type ===
                                                                        'SINGLE' &&
                                                                        reqItem.quantity &&
                                                                        round(
                                                                            reqItem.quantity -
                                                                                getTotalDelivered(
                                                                                    reqItem,
                                                                                    order.data
                                                                                ),
                                                                            DECIMAL_PLACES
                                                                        )}
                                                                </TableData>
                                                                <TableData>
                                                                    {reqItem.quantity}
                                                                </TableData>
                                                                <TableData>
                                                                    <div className="flex justify-between">
                                                                        <span>
                                                                            {fomatCurrencySymbol(
                                                                                reqItem.currency ||
                                                                                    ''
                                                                            )}
                                                                        </span>
                                                                        <span>
                                                                            {formatCurrencyAmount(
                                                                                reqItem.buyOutRate ||
                                                                                    0
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </TableData>
                                                                <TableData>
                                                                    <div className="flex justify-between">
                                                                        <span>
                                                                            {fomatCurrencySymbol(
                                                                                reqItem.currency ||
                                                                                    ''
                                                                            )}
                                                                        </span>
                                                                        <span>
                                                                            {formatCurrencyAmount(
                                                                                (reqItem.quantity ||
                                                                                    0) *
                                                                                    (reqItem.buyOutRate ||
                                                                                        0)
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </TableData>
                                                            </TableRow>
                                                        )
                                                    )}
                                                {order.data && order.data.lineItems && (
                                                    <>
                                                        <tr className="text-xs font-bold">
                                                            <td colSpan={8} />
                                                            <td className="border p-1 w-36">
                                                                Sub Total Amount
                                                            </td>
                                                            <td className="border p-1 flex justify-between">
                                                                <span>
                                                                    {fomatCurrencySymbol(
                                                                        order.data.currency || ''
                                                                    )}
                                                                </span>
                                                                <span>
                                                                    {getOrderTotal(
                                                                        order.data.lineItems || []
                                                                    )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="text-xs font-bold">
                                                            <td colSpan={8} />
                                                            <td className="border p-1">
                                                                VAT Amount
                                                            </td>
                                                            <td className="border p-1 flex justify-between">
                                                                <span>
                                                                    {fomatCurrencySymbol(
                                                                        order.data.currency || ''
                                                                    )}
                                                                </span>
                                                                <span>
                                                                    {formatCurrencyAmount(
                                                                        getVatTotal(
                                                                            order.data.lineItems ||
                                                                                []
                                                                        )
                                                                    )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="text-xs font-bold">
                                                            <td colSpan={8} />
                                                            <td className="border p-1">
                                                                Total Amount
                                                            </td>
                                                            <td className="border p-1 flex justify-between">
                                                                <span>
                                                                    {fomatCurrencySymbol(
                                                                        order.data.currency || ''
                                                                    )}
                                                                </span>
                                                                <span>
                                                                    {getTotalAmount(
                                                                        getOrderRawTotal(
                                                                            order.data.lineItems
                                                                        ),
                                                                        getVatTotal(
                                                                            order.data.lineItems ||
                                                                                []
                                                                        )
                                                                    )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                {selectedTab === 'Deliveries' && (
                                    <div className="space-y-5 pt-5">
                                        {user &&
                                            order.data &&
                                            order.data.cancelledAt === null &&
                                            order.data.cancelledBy === null &&
                                            order.data.approvedAt !== null &&
                                            order.data.approvedBy !== null &&
                                            getPermissions.data?.items.find(
                                                (p) => p.code === 'Order_Create_Delivery'
                                            )?.hasAccess && (
                                                <div className="flex">
                                                    <Button
                                                        onClick={() =>
                                                            handleCreateDeliveryModalOpen()
                                                        }
                                                    >
                                                        Add Delivery
                                                    </Button>
                                                </div>
                                            )}
                                        <div className="text-l font-bold">Delivery History</div>
                                        {order.data && order.data.orderDeliveries && (
                                            <Table<Delivery>
                                                data={order.data.orderDeliveries}
                                                onClick={(i) => toDeliveryDetail(i.id)}
                                                columns={[
                                                    {
                                                        key: 'note',
                                                        title: 'Delivery Note',
                                                        render: (delivery) => `${delivery.note}`,
                                                    },
                                                    {
                                                        key: 'deliveredAt',
                                                        title: 'Delivered At',
                                                        render: (delivery) =>
                                                            delivery.deliveredAt &&
                                                            new Date(
                                                                delivery.deliveredAt
                                                            ).toLocaleDateString('en-ZA'),
                                                    },
                                                ]}
                                            />
                                        )}
                                    </div>
                                )}
                                {selectedTab === 'Call-Offs' && (
                                    <div className="space-y-5 pt-5">
                                        {/* Calloffs can be added by level 3 and higher + buyers at any time. level 1 & 2 can only add if not approved */}
                                        {order.data &&
                                            order.data.cancelledAt === null &&
                                            order.data.cancelledBy === null &&
                                            ((order.data.approvedAt !== null &&
                                                order.data.approvedBy !== null &&
                                                getPermissions.data?.items.find(
                                                    (p) =>
                                                        p.code === 'Order_Create_CallOff_IfApproved'
                                                )?.hasAccess) ||
                                                getPermissions.data?.items.find(
                                                    (p) => p.code === 'Order_Create_CallOff'
                                                )?.hasAccess) && (
                                                <div className="flex flex-row-reverse">
                                                    <Button
                                                        onClick={() =>
                                                            handleCreateCallOffModalOpen()
                                                        }
                                                    >
                                                        Add Call-Off
                                                    </Button>
                                                </div>
                                            )}
                                        <div className="text-l">Call-Off History</div>
                                        {order.data && order.data.callOffs && (
                                            <Table<CallOff>
                                                data={order.data.callOffs}
                                                onClick={(i) => toCallOffDetail(i.id)}
                                                columns={[
                                                    {
                                                        key: 'number',
                                                        title: 'Number',
                                                        render: (callOff) => `${callOff.number}`,
                                                    },
                                                    {
                                                        key: 'description',
                                                        title: 'Call-Off Note',
                                                        render: (callOff) =>
                                                            `${callOff.description}`,
                                                    },
                                                    {
                                                        key: 'requiredAt',
                                                        title: 'Required At',
                                                        render: (callOff) =>
                                                            callOff.requiredAt &&
                                                            new Date(
                                                                callOff.requiredAt
                                                            ).toLocaleDateString('en-ZA'),
                                                    },
                                                ]}
                                            />
                                        )}
                                    </div>
                                )}
                            </TabsContainer>
                        </div>
                        {/* HERE */}
                        {renderOrderButtons()}
                        {errorMessage && (
                            <div className="flex p-5 text-red-500">{errorMessage}</div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
